import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/cloud-application-development/Anchors'
import CloudAppDevBenefits from '~/mock/services/cloud-application-development/CloudAppDevBenefits'
import CloudAppDevBusinesses from '~/mock/services/cloud-application-development/CloudAppDevBusinesses'
import CloudAppDevFAQ from '~/mock/services/cloud-application-development/CloudAppDevFAQ'
import CloudAppDevModels from '~/mock/services/cloud-application-development/CloudAppDevModels'
import CloudAppDevProcess from '~/mock/services/cloud-application-development/CloudAppDevProcess'
import CloudAppDevServices from '~/mock/services/cloud-application-development/CloudAppDevServices'
import CloudAppDevTabContent from '~/mock/services/cloud-application-development/CloudAppDevTabContent'
import CloudAppDevTabList from '~/mock/services/cloud-application-development/CloudAppDevTabList'
import CloudAppIndustries from '~/mock/services/cloud-application-development/CloudAppIndustries'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import useCloudAppDevelopmentStaticQuery from '~/views/Services/CloudAppDevelopment/useCloudAppDevelopmentStaticQuery'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'

import * as containerStyles from './CloudAppDevelopment.module.scss'

const CloudAppDevelopment = () => {
  const query = useCloudAppDevelopmentStaticQuery()
  const cloudAppDevTabContent = CloudAppDevTabContent()
  const clientPMK = getImage(fileToImageLikeData(query.clientPMK))

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.cloudAppDevPrimeSection}>
        <PrimeSectionServices
          titleTop="Cloud App"
          title="Development Services"
          description="Unlock the power of the cloud! You can transform your ideas into reality with our cutting-edge cloud application development services. Get started moving to the cloud today!"
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbCurrent="Cloud App Development Services"
          breadcrumbCurrentPath="/services/cloud-application-development/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.cloudAppDevServicesSection}
      >
        <HeadSection
          position="center"
          title="Full-scope cloud application development services"
          description={
            <>
              As a
              <Link
                to="/services/custom-software-development/"
                className="ml5"
                key="keyLinkDescription"
              >
                software development company
              </Link>
              , Codica specializes in creating and deploying cloud-based apps
              that are optimized for flexibility and cost-effectiveness.
              <span className="mb-2 d-block" key="keySpanDescription" />
              Our services include cloud consulting and strategy, app design and
              development, migrating and integrating existing apps to the cloud,
              testing, and maintenance. Take advantage of this, and start your
              cloud app development journey with Codica!
            </>
          }
          descriptionLarge
        />
        <Service3DIconCard dataContent={CloudAppDevServices} />
      </section>

      <section className={containerStyles.cloudAppDevModelSection} id="models">
        <HeadSection
          position="center"
          title="Different types of deployment models in the cloud we offer"
          description="In cloud mobile app development, four different cloud models are commonly used. Regardless of the model, cloud frameworks must provide dynamic scalability, growth, and deployment speed."
          titleLarge
        />
        <ServicesIconSmallCard isColSm dataContent={CloudAppDevModels} />
      </section>

      <CallToAction
        title="Are you ready to transform your business operations?"
        subtitle="We can build a revolutionized custom cloud application."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="business-type"
        className={containerStyles.cloudAppDevBusinessSection}
      >
        <HeadSection
          position="center"
          title="Providing cloud development services for businesses of any scale"
          description="Our experience in developing excellent cloud solutions covers different business sizes. Also, as a cloud application development company, we thoroughly understand your project challenges, and we can help you overcome them by utilizing our advanced technologies."
          titleLarge
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={CloudAppDevBusinesses} />
      </section>

      <section
        className={containerStyles.cloudAppDevIndustriesSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Our experience developing software for specific fields has enabled us to grow our in-house expertise, which we are open to sharing with our clients. We offer consultations on every phase of software development and make reliable and modern products."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={CloudAppIndustries} />
      </section>

      <section
        id="process"
        className={containerStyles.cloudAppDevProcessSection}
      >
        <HeadSection
          position="center"
          title="Our cloud application development process"
          description="Codica’s specialists always follow a proven development process to deliver your project. This process covers different stages of development before you get a complete package of our cloud app development services."
          titleLarge
          descriptionLarge
        />
        <ServicesProcess dataContent={CloudAppDevProcess} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.cloudAppDevTechStackSection}
      >
        <HeadSection
          position="center"
          title="Choose the best tech stack on the market"
          description="During the cloud-based application development process, our specialists work with different technologies and architecture patterns for creating advanced cloud-based solutions. Our choice depends on the peculiarities and features of each project to be built."
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section
        id="benefits"
        className={containerStyles.cloudAppDevBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Essential benefits of cloud app development with Codica"
          description={
            <>
              As a
              <Link
                to="/services/mobile-app-development/"
                className="ml5"
                key="keyLinkDescriptionMobileApp"
              >
                mobile app development company
              </Link>
              , we combine our expert technical knowledge with best practices to
              create scalable, efficient, and secure cloud solutions. Moreover,
              we always ensure our projects meet all modern cloud application
              development standards.
            </>
          }
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={CloudAppDevBenefits} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.cloudAppDevProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <CallToAction
        title="Is your business ready to embrace the cloud?"
        subtitle="Let us help you develop innovative cloud-based apps."
        classNameForGA="servicesCTA_2"
        linkName="Get in touch with us"
        link="/contacts/"
      />

      <section className={containerStyles.cloudAppDevCasesSection} id="cases">
        <HeadSection position="left" title="Featured case studies" />
        <DiscoverWorksTabs
          dataTabList={CloudAppDevTabList}
          dataContent={cloudAppDevTabContent}
        />
      </section>

      <section className={containerStyles.cloudAppDevOurAchievementsSection}>
        <HeadSection
          position="center"
          title="We have a huge experience in creating cloud-based solutions"
          description="At Codica, we are an expert team of 60+ professionals ready to deliver your business idea. With over 50 exciting projects created for our clients, we guarantee our expertise regarding custom software development."
          titleLarge
          descriptionLarge
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.cloudAppDevQuotesSection}>
        <HeadSection
          position="center"
          title="Read about Codica’s expertise from our clients"
          description="So, our clients have generously shared their experiences working with us, giving you an authentic glimpse into the impact Codica has had on their businesses. The Codica team had the opportunity of partnering with diverse clients, and we are proud to showcase their stories here."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="David Watkins"
            location="USA"
            position="Founder of PlanMyKids"
            image={clientPMK}
            quote={[
              {
                text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
              },
            ]}
            link="kids-activities-service-marketplace"
            videoLink="https://www.youtube.com/embed/dzxMCDLghZQ"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s develop an excellent cloud app together!"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our expert app developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={CloudAppDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default CloudAppDevelopment
