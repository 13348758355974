// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "T_jF";
export var cloudAppDevBusinessSection = "T_jy";
export var cloudAppDevCasesSection = "T_jH";
export var cloudAppDevIndustriesSection = "T_jz";
export var cloudAppDevModelSection = "T_jx";
export var cloudAppDevOurAchievementsSection = "T_jD";
export var cloudAppDevPrimeSection = "T_jv";
export var cloudAppDevProcessSection = "T_jB";
export var cloudAppDevProjLogoSection = "T_jG";
export var cloudAppDevQuotesSection = "T_jJ";
export var cloudAppDevServicesSection = "T_jw";
export var cloudAppDevTechStackSection = "T_jC";